import React from "react";
import PropTypes from "prop-types";

const Section = ({ children, ...rest }) => {
  return <section {...rest}>{children}</section>;
};

Section.propTypes = {
  children: PropTypes.node.isRequired
};

export default Section;
